@import 'https://fonts.googleapis.com/css?family=Roboto:400,700';
@import 'https://fonts.googleapis.com/css?family=Roboto+Slab|Roboto:400,700';

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    background: #ffffff;
    font-size: 100%;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color: #333333;
    line-height: 135%;
}

h1 {
    font-size: 2em;
    color: #00274c;
    line-height: 115%;
}

h2 {
    font-size: 1.75em;
    color: #40658f;
    line-height: 115%;
}

h3 {
    font-size: 1.5em;
    color: #555555;
    line-height: 115%;
}

a:link {
    color: #0075bc;
    text-decoration: underline;
}

a:visited {
    color: #0075bc;
    text-decoration: underline;
}

a:hover {
    color: #cc3300;
    text-decoration: underline;
}

a:focus {
    outline: thin dotted #ffffff;
    background: #0075bc;
    color: #ffffff;
}


.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    line-height: 150%;
}

#warningHeader {
    margin-bottom: 0;
    margin-top: 2em;
    padding: 10px;
    background: #40658f;
    border-bottom: none;
    color: #ffffff;
    line-height: normal;
    overflow: auto;
    text-decoration: none;
    font-weight: 900;
    font-size: 1.2em;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

}

.alert-info {
    /*    border-color: #bce8f1;*/
    border-color: #c3e2f2;
    background-color: #d9edf7;
    color: #055278;
}

.alert-warning {
    border-color: #ebe6d7;
    background-color: #f5f2ea;
    color: #655a52;
}

.alert-warningred  {
    border-color: #ebccd1;
    background-color: #f2dede;
    color: #aa4241;
    margin-top:0;
    border-top: none;
    border-radius:0;
    padding: 20px;
    margin-bottom: 2em;  
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
   
}
.alert-warningred  b {
    font-weight: bold;
    text-decoration: underline;
    font-size: 110%;

   
}

.alert h2:first-child {
    margin-top: 0;
}

.alert p:last-child {
    margin-bottom: 0;
}


i {
    font-style: normal;
}

.sr-only {
    position: absolute;
    left: -99999px;
    overflow: hidden;
}

#bannerContainer {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #00274c;
    text-align: center;
}

#pageBanner {
    width: 90%;
    min-width: 940px;
    max-width: 1320px;
    margin: 0 auto 0 auto;
    padding: 8px 20px;
    overflow: auto;
}

#pageBanner #banner {
    text-align: left;
}

#pageBanner #banner img {
    width: 70%;
    max-width: 820px;
    height: auto;
    display: none;
}

#pageBanner #banner {
    display: block;
    margin: 0;
    padding: 6.34% 0 0 0;
    width: 100%;
    height: auto;
    max-width: 820px;
    background: url('its-logo@3x.png') 0 50% no-repeat;
    background-size: contain;
}

#breadcrumbcontainer {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #e4e1df;
    text-align: center;
}

#breadcrumb {
    width: 90%;
    min-width: 940px;
    max-width: 1320px;
    margin: 0 auto 0 auto;
    padding: 1em 20px 1em 20px;
    font-size: .9em;
    text-align: left;
}

#breadcrumb a:link, #breadcrumb a:visited {
    color: #333333;
    text-decoration: none;
}

#breadcrumb a:hover {
    color: #cc3300;
}

#breadcrumb a:focus {
    background: #655a52;
    color: #ffffff;
}

#breadcrumb p {
    margin: 0;
}

#pagecontainer {
    margin: 0;
    padding: 0;
    overflow: auto;
}

#pagecontent {
    width: 90%;
    min-width: 940px;
    max-width: 1320px;
    margin: 0 auto 0 auto;
    padding: 0 20px 2em 20px;
    text-align: left;
    overflow: auto;
}

.please-contact {
    /*	font-size:1.25em;*/
    color: #cc3300;
    color: #d32f2f;
    line-height: inherit;
}

.powered-by {
    display: none;
    visibility: hidden;
    clear: both;
    margin: 0;
    padding-top: 4em;
}

.nobr {
    white-space: nowrap;
}

#footercontainer {
    clear: both;
    width: auto;
    margin: 2em 0 0 0;
    padding: 0;
    background: #333333;
    overflow: auto;
}

#pagefooter {
    width: 90%;
    min-width: 940px;
    max-width: 1320px;
    margin: 0 auto 0 auto;
    padding: 20px;
    text-align: left;
    color: #ffffff;
    line-height: 150%;
    overflow: auto;
}

#pagefooter a.umich {
    display: block;
    width: 140px;
    height: 152px;
    background: #00274c url('um-logo-m216.png') no-repeat;
    background-size: cover;
    text-indent: -99999px;
}

#pagefooter .links h2 {
    margin: auto;
    font-size: 1em;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color: #ffffff;
    line-height: inherit;
}

#pagefooter .links {
    float: left;
    /*	width:40%;*/
    margin: 0 40px 2em 0;
    padding: 0;
    white-space: nowrap;
}

#pagefooter .links ul {
    margin: 0 0 0 10px;
    padding: 0 0 0 10px;
}

#pagefooter .links ul li {
    color: #555555;
    margin: .5em 0 0 0;
}

#pagefooter .links .socialmedia {
    margin: .5em 0 1em 0;
}

#pagefooter .links .socialmedia a:hover {
    text-decoration: none;
}

#pagefooter .social-icon {
    display: inline-block;
    width: 1.25em;
    font-size: 1.5em;
    font-family: fontawesome;
    font-weight: normal;
    font-style: normal;
    text-rendering: optimizelegibility;
    text-align: center;
    vertical-align: middle;
    line-height: 1.25em;
}

#pagefooter a:link, #pagefooter a:visited {
    color: #ffffff;
    text-decoration: none;
}

#pagefooter a:hover {
    color: #ffcb05;
    text-decoration: underline;
}

#pagefooter a:active {
    color: #ffcb05;
    text-decoration: underline;
}

#pagefooter .links .socialmedia {
    margin: .5em 0 1em 0;
}

#pagefooter .links .socialmedia a {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    line-height: 2.7rem;
    margin-right: .5em;
    border: 1px solid #555555;
    border-radius: 100%;
    font-size: 1.15rem;
    font-family: 'Fontawesome';
    text-align: center;
}

#pagefooter .links .socialmedia a:hover {
    background: #555555;
    color: #ffffff;
    text-decoration: none;
}

#pagefooter .links .socialmedia a:active, .region-footer .links .socialmedia a:focus {
    background: #0075bc;
    border: 1px solid #0075bc;
    color: #ffffff;
    text-decoration: none;
}

#pagefooter .links.right {
    float: right;
}

#pagefooter .links.right ul li:first-child {
    margin: 0;
}

#pagefooter .copyright {
    clear: both;
    font-size: .85em;
    color: #9b9b9b;
    /*	text-align:right;*/
}

#pagefooter .copyright a:link, .copyright a:visited {
    color: #9b9b9b;
    text-decoration: none;
}

#pagefooter .copyright a:hover {
    color: #ffcb05;
    text-decoration: underline;
}

#pagefooter .copyright a:focus {
    outline: thin dotted #ffffff;
    background: #0075bc;
    color: #ffffff;
}

/* RESPONSIVE STYLES */

@media (max-width: 1279px) {
    #pageBanner,
    #breadcrumb,
    #pagecontent,
    #pagefooter {
        width: auto;
        min-width: 0;
    }
}

@media (max-width: 959px) {
    #pageBanner {
        width: auto;
        min-width: 0;
    }

    #pageBanner #banner {
        margin: 1em 0 1em 0;
    }
}

@media (max-width: 499px) {
    #pagefooter .links,
    #pagefooter .links.right {
        float: none;
    }
}
