@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab|Roboto:400,700);
/***************************************************************** ELEMENTS */

body {
    color: #505153;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    text-align: center;
    background-color: #ffffff;
}

a {
    text-decoration: none;
    color: #ff5500;
}

a:hover {
    text-decoration: underline;
    color: #505153;
}

input,
select,
textarea,
button {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
}

code,
input[type=text],
input[type=password],
select,
textarea {
    background-color: #B9D9EB;
    border: 1px solid #5f6063;
    border-radius: 3px;
}

input[type=button],
input[type=reset],
input[type=submit],
button {
    background-color: #ff5500;
    border: 1px solid #505153;
    color: white;
    border-radius: 7px;
}

input[disabled],
select[disabled],
textarea[disabled] {
    background-color: #fff;
    border-color: #ccc;
    color: #ccc;
}

hr {
    height: 1px;
    border: 0px;
    color: #C2BFBF;
    background-color: #C2BFBF;
}

code {
    color: black;
    border: 1px outset #000000;
    padding: 0.5em;
    font-family: "Courier New", Courier, monospace;
}

p {
    margin: 1em 0;
}



pre {
    padding: 1.0em;
    background-color: #eee;
    border: 0.1em dashed #505153;
    font-size: 1.0em;
}

img,
a img {
    border-width: 0;
}

div#container,
.container {
    width: 60em;
    margin: 0 auto;
    text-align: left;
}

table {
    background-color: transparent;
    border-collapse: collapse;
}

td,
th,
form.tableless label {
    margin: .1em;
    padding: .1em;
}

form.tableless label,
th {
    text-align: right;
    vertical-align: top;
    font-weight: 800;
    min-width: 30px;
}

form.tableless label a,
th a {
    color: #505153;
}

form.tableless br {
    clear: left;
    margin-bottom: .7em;
}

thead tr th,
tr.header th {
    text-align: center;
    vertical-align: bottom;
    min-width: 30px;
}

tr.header th div.expander {
    text-align: left;
    float: left;
}

.note {
    font-size: .8em;
    color: #aaa;
}

.editViewNote {
    margin-top: 0.25em;
    margin-left: 1.5em;
    color: #6F7072;
}

.editViewNote.last {
    margin-bottom: 1.0em;
}

/*.editViewNote.last:last-child {
	margin-bottom: 0.1em;
}*/

tr.even {
    background-color: #f0f0f0;
}

tr.odd {
    background-color: #f8f8f8;
}

tr.userdisabled,
span.userdisabled {
    background-color: #eaa;
}

#header a {
    font-weight: bold;
    text-decoration: none;
}

#footer {
    padding: .5em 1em;
    color: #505153;
    font-size: .9em;
}

#footer a {
    color: #505153;
}

/***************************************************************** MENU */

ul.menu {
    list-style-type: none;
    margin: 1em 0em 0em 0em;
    padding: 0.1em 0 0 2em;
    background-color: #ff5500;
    clear: both;
    border-style: solid;
    border-color: #C2BFBF;
    border-width: 0;
    border-top-width: 0.1em;
    border-bottom-width: 0.1em;
    text-align: right;
    text-transform: uppercase;
}

ul.menu li {
    display: inline;
    margin: 0;
    padding: .1em .6em;
}

#header ul.menu li a,
ul.menu li a {
    color: white;
    font-weight: bold;
    text-decoration: none;
}

#header ul.menu li a:hover,
ul.menu li a:hover {
    color: #c2bfbf;
}

ul.menu li.selected {
    background-color: white;
    border: 0.1em solid #c2bfbf;
    border-style: solid solid none solid;
}

#header ul.menu li.selected a,
ul.menu li.selected a {
    color: #ff5500;
}

div.submenu {
    margin: 0 0 -1em 0;
    min-height: 1em;
    text-transform: uppercase;
}

div.submenu ul {
    text-align: right;
    clear: both;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

div.submenu ul.selected {
    display: block;
}

div.submenu ul li {
    display: inline-block;
    margin: 0;
    padding: .2em .6em 0 .6em;
    white-space: nowrap;
    border-left: .2em solid #c2bfbf;
}

#header div.submenu ul li a,
div.submenu ul li a {
    color: #505153;
    text-decoration: none;
    font-weight: normal;
}

div.submenu ul li:hover {
    background-color: #eee;
}

#header div.submenu ul li.selected a,
div.submenu ul li.selected a {
    color: #ff5500;
}

#header div.submenu ul li.selected a:hover,
div.submenu ul li.selected a:hover {
    color: black;
}

.occlude {
    background-color: #B9D9EB;
    border: 0.1em solid #c2bfbf;
    z-index: 100;
}

.boxTitle {
    font-size: 1em;
    color: #505153;
    text-decoration: none;
    font-weight: bold;
}

.error {
    color: red !important;
    font-weight: bold;
}

.warning {
    color: goldenrod !important;
    font-weight: bold;
}

.info {
    color: black !important;
    font-weight: bold;
}

.success {
    color: blue !important;
    font-weight: bold;
}

.fakeDisabled {
    background-color: #fff;
    border-color: #ccc;
    color: #ccc;
}

/* .contentBox {
    width: 100%;
    margin: .75em;
    padding: 2em;
    border: .2em solid #c2bfbf;
} */

/* IE[456] doesn't understand the '>' child selector
We hide this from IE[456] because it really screws up page layout when you set auto width */

* > .contentBox {
    width: auto;
}

/*
 * h1 should ONLY be the VERY first thing inside contentBox (-10 margin-top)
 * h2 and h3 can be used anywhere inside contentBox to insert a heading
 * h2 is a major divider: it looks graphically exactly like h1 (except the margin-top)
 * h3 looks like a subheading (less padding, indented, smaller font)
 */

.contentBox h1,
.contentBox h2,
.contentBox h3 {
    margin: -1.67em -1.67em 1em -1.67em;
    padding: .4em 1.33em;
    background-color: #505153;
    border-bottom: .1em solid #c2bfbf;
    color: white;
    text-decoration: none;
    font-weight: 900;
    font-size: 1.2em;
}

.contentBox h2 {
    border-top: .1em solid #c2bfbf;
    margin: 1em -1em 1em -1em;
}

.contentBox h3 {
    /* Leave whitespace on the sides to show it's a subheading */
    margin: 1.2em 0;
    padding: .2em 1.2em;
    border: .1em solid #c2bfbf;
    font-size: 1.1em;
}

.contentBox h3.error,
.contentBox h3.success {
    background-color: #eee;
    text-align: center;
    font-weight: bold;
}

.pageTitle {
    font-size: 1.8em;
}

.wide {
    width: 100%;
}

table.grid {
    background-color: white;
    border: .2em solid #cbdbeb;
}

table.grid tr td {
    border: .1em solid #cbdbeb;
}

table.grid tr th {
    background-color: #B9D9EB;
    border: .2em solid #cbdbeb;
}

table.grid tr th[scope='row'] {
    width: 20%;
}

.padding,
table.padding td,
table.padding th {
    padding: .3em;
}

span.required,
td.required span {
    /*background: transparent url(/content/reddot.png) no-repeat top left;*/
    padding-left: 7px;
}

a.inverse {
    color: #505153;
}

a.inverse:hover {
    color: #ff5500;
}

.tab {
    padding-left: .8em;
    padding-right: .8em;
}

.lang-selection img {
    vertical-align: bottom;
}

form.tableless > div {
    display: flex;
    flex-direction: column;
}

form.tableless > div > div {
    display: flex;
}

form.tableless label {
    display: block;
    padding: 0pt 1em;
    text-align: right;
    width: 12em;
    color: black;
}

form.tableless span {
    display: block;
    margin-bottom: 0.5em;
    padding: 0pt;
}

form.tableless span select {
    padding: 1px;
}

.accessible {
    position: absolute;
    top: 0em;
    left: -500em;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.group-td-vert.answers {
    min-width: 15em;
    white-space: nowrap;
}

.group-td-vert.answers .choice {
    white-space: nowrap;
    margin-right: 0.5em;
}

.floatClear {
    display: block;
    clear: both;
}

.support_access_policy_id_class,
.jump_policy_id_class {
    width: 24em;
}
body {
    position: relative;
}

#header .pageTitle {
    text-align: left;
    color: black;
    line-height: 1.5em;
}

#header .pageTitleContainer {
    float: left;
}

#header .lang-selection {
    text-align: left;
}

#header.header-wrapper .lang-selection > * {
    float: left;
}

#header.header-wrapper .lang-selection > *:first-child {
    margin-right: 6px;
}

#header.header-wrapper > div {
    display: inline-block;
}

#header.header-wrapper .logo {
    float: right;
    text-align: right;
}

#header.header-wrapper .logo img {
    max-width: 250px;
    max-height: 64px;
}

#header .lang-selection svg {
    width: 20px;
    height: 20px;
}

.sectionTitle {
    text-align: left;
    line-height: 1.4em;
}

.sectionTitle span {
    float: left;
    display: block;
}

.sectionTitle a[rel="help"] img {
    vertical-align: middle;
}

.ambushContentBox {
    color: black;
    background-color: #bad8eb;
}

.ambushContentBox .sectionTitle {
    text-align: left;
}

ul.scheduleMessageList {
    list-style-type: none;
}

ul.ambushMessageList {
    margin: 0;
    padding: 0;
    list-style-position: inside;
}

ul.ambushMessageList > li {
    margin-bottom: 15px;
}

ul.ambushMessageList > li:last-of-type {
    margin-bottom: 0;
}

#powered-by {
    font-size: .9em;
    padding: 0.5em 1em;
}

/* Recording Viewer */

.recordingViewer {
    width: 530px;
    height: 300px;
    margin: 20px auto;
}

/* Rep Invite Join / Download Instructions */

#repInviteChoices {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
}

#repInviteChoices.chrome-os {
    justify-content: center;
}

#repInviteChoices h4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    text-align: center;
    max-width: 100%;
}

#repInviteChoices h4 a {
    display: inline-flex;
    flex-direction: row;
    align-items: inherit;
    max-width: 100%;
}

#repInviteChoices ul {
    max-width: 100%;
}

#repInviteChoices > div:first-of-type h4 img {
    margin-right: 5px;
}

#repInviteChoices .or {
    font-size: large;
    align-self: center;
}

#exitSurveyForm .group-td-vert,
#exitSurveyForm .group-td-vert.answers,
#exitSurveyForm .group-td-vert.answers .choice {
    display: block;
}

.params {
    display: none;
}

/* For elements that need to be hidden when the page first loads.*/

.hidden {
    display: none;
}

#publicFileStore #header > span {
    height: 41px;
    float: left;
}

#publicFileStore #header .pageTitle {
    text-align: right;
    line-height: 60px;
}

#publicFileStore #footer > div {
    float: right;
}

#exitSurveyForm .wrapper {
    margin-left: 1em;
}

#exitSurveyForm .submitButton {
    margin-top: 1em;
}

#exitSurveyForm .questionDiv {
    margin-top: 1em;
}

#jumpAuthRequestForm .submit {
    margin: 4px;
}

#jumpAuthRequestForm .request-text {
    white-space: pre-wrap;
}

#jumpAuthRequestForm > table {
    width: 100%;
}

#jumpAuthRequestForm #comments {
    width: 98%;
}

#chatTranscriptBox hr {
    width: 85%;
}
/* modern mobile / small screen */

@media only screen and (max-device-width: 979px) {
    * {
        box-sizing: border-box;
    }

    #header {
        padding: 10px;
    }

    #header .pageTitle {
        font-size: 22px;
    }

    #header .logo-container {
        height: 44px;
        float: left;
    }

    #logo {
        width: 80%;
        height: 80%;
    }

    div#container {
        width: 100%;
        margin: 0;
    }

    /* .contentBox {
        margin: 0;
        margin-bottom: 8px;
        padding: 24px 12px 12px 12px;
        border: 2px solid #c2bfbf;
    } */
    .contentBox h1 {
        font-size: 16px;
    }

    .contentBox h2 {
        font-size: 14px;
    }

    .contentBox h3 {
        font-size: 12px;
    }

    .contentBox h1,
    .contentBox h2,
    .contentBox h3 {
        margin: -24px -12px 12px -12px;
        margin-bottom: 6px;
        padding: 4px 6px;
    }

    form.tableless label {
        padding: 3px 0;
        text-align: left;
        width: auto;
        clear: right;
    }

    form.tableless span {
        margin-bottom: 6px;
        display: block;
        clear: both;
    }

    form.tableless input[type=text],
    form.tableless textarea {
        width: 100%;
        max-width: 524px;
    }

    input,
    select {
        height: 24px;
    }

    input,
    select,
    textarea,
    button {
        font-size: inherit;
    }

    .repLink {
        font-size: 14px;
    }

    #footer {
        padding: 6px;
        text-align: center;
    }

    #powered-by {
        margin: 6px;
    }

    input[type=button],
    input[type=reset],
    input[type=submit],
    button {
        background-color: #ff5500;
        border: 1px solid #5f6062;
        color: white;
        border-radius: 7px;
        display: inline-block;
        padding: 4px 12px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        background-repeat: repeat-x;
        /* this non-gradient gradient prevents mobile safari's default button gradient */
        background-image: linear-gradient(to bottom, #FF7A42 0%, #FF5500 50%, #E64C00 100%);
    }

    input[type=button][disabled],
    input[type=reset][disabled],
    input[type=submit][disabled],
    button[disabled] {
        color: #ccc;
        border-color: #ccc;
        background-color: #fff;
        background-image: linear-gradient(to bottom, #fff 0%, #fff 100%);
    }
}

/* just modern phone, portrait */

@media only screen and (max-width: 767px) and (orientation: portrait) {
    #header .pageTitle {
        text-align: left;
    }

    #header .lang-selection {
        text-align: left;
    }
}

/* Legacy devices */

@media handheld {
    #logo {
        width: 100px;
        height: 20px;
        text-align: right;
    }

    .sectionTitle {
        text-align: left;
    }

    .sectionTitle span {
        float: none;
        display: inline;
    }

    form.tableless label,
    form.tableless span {
        text-align: left;
    }

    .accessible {
        display: none !important;
    }
}

ul.section-start-buttons {
    list-style-type: none;
}

.session-start-buttons {
    padding: 0;
    margin: 15px 0;
}

.session-start-buttons li {
    padding: 0;
    margin: 0 0 16px;
}

.session-start-buttons button {
    font-size: 21px;
    display: block;
    padding: 8px;
    margin: 16px auto;
    width: 160px;
}
* {
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    background: #ffffff;
    font-size: 100%;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color: #333333;
    line-height: 135%;
}

h1 {
    font-size: 2em;
    color: #00274c;
    line-height: 115%;
}

h2 {
    font-size: 1.75em;
    color: #40658f;
    line-height: 115%;
}

h3 {
    font-size: 1.5em;
    color: #555555;
    line-height: 115%;
}

a:link {
    color: #0075bc;
    text-decoration: underline;
}

a:visited {
    color: #0075bc;
    text-decoration: underline;
}

a:hover {
    color: #cc3300;
    text-decoration: underline;
}

a:focus {
    outline: thin dotted #ffffff;
    background: #0075bc;
    color: #ffffff;
}


.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    line-height: 150%;
}

#warningHeader {
    margin-bottom: 0;
    margin-top: 2em;
    padding: 10px;
    background: #40658f;
    border-bottom: none;
    color: #ffffff;
    line-height: normal;
    overflow: auto;
    text-decoration: none;
    font-weight: 900;
    font-size: 1.2em;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

}

.alert-info {
    /*    border-color: #bce8f1;*/
    border-color: #c3e2f2;
    background-color: #d9edf7;
    color: #055278;
}

.alert-warning {
    border-color: #ebe6d7;
    background-color: #f5f2ea;
    color: #655a52;
}

.alert-warningred  {
    border-color: #ebccd1;
    background-color: #f2dede;
    color: #aa4241;
    margin-top:0;
    border-top: none;
    border-radius:0;
    padding: 20px;
    margin-bottom: 2em;  
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
   
}
.alert-warningred  b {
    font-weight: bold;
    text-decoration: underline;
    font-size: 110%;

   
}

.alert h2:first-child {
    margin-top: 0;
}

.alert p:last-child {
    margin-bottom: 0;
}


i {
    font-style: normal;
}

.sr-only {
    position: absolute;
    left: -99999px;
    overflow: hidden;
}

#bannerContainer {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #00274c;
    text-align: center;
}

#pageBanner {
    width: 90%;
    min-width: 940px;
    max-width: 1320px;
    margin: 0 auto 0 auto;
    padding: 8px 20px;
    overflow: auto;
}

#pageBanner #banner {
    text-align: left;
}

#pageBanner #banner img {
    width: 70%;
    max-width: 820px;
    height: auto;
    display: none;
}

#pageBanner #banner {
    display: block;
    margin: 0;
    padding: 6.34% 0 0 0;
    width: 100%;
    height: auto;
    max-width: 820px;
    background: url(/static/media/its-logo@3x.62fbefde.png) 0 50% no-repeat;
    background-size: contain;
}

#breadcrumbcontainer {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #e4e1df;
    text-align: center;
}

#breadcrumb {
    width: 90%;
    min-width: 940px;
    max-width: 1320px;
    margin: 0 auto 0 auto;
    padding: 1em 20px 1em 20px;
    font-size: .9em;
    text-align: left;
}

#breadcrumb a:link, #breadcrumb a:visited {
    color: #333333;
    text-decoration: none;
}

#breadcrumb a:hover {
    color: #cc3300;
}

#breadcrumb a:focus {
    background: #655a52;
    color: #ffffff;
}

#breadcrumb p {
    margin: 0;
}

#pagecontainer {
    margin: 0;
    padding: 0;
    overflow: auto;
}

#pagecontent {
    width: 90%;
    min-width: 940px;
    max-width: 1320px;
    margin: 0 auto 0 auto;
    padding: 0 20px 2em 20px;
    text-align: left;
    overflow: auto;
}

.please-contact {
    /*	font-size:1.25em;*/
    color: #cc3300;
    color: #d32f2f;
    line-height: inherit;
}

.powered-by {
    display: none;
    visibility: hidden;
    clear: both;
    margin: 0;
    padding-top: 4em;
}

.nobr {
    white-space: nowrap;
}

#footercontainer {
    clear: both;
    width: auto;
    margin: 2em 0 0 0;
    padding: 0;
    background: #333333;
    overflow: auto;
}

#pagefooter {
    width: 90%;
    min-width: 940px;
    max-width: 1320px;
    margin: 0 auto 0 auto;
    padding: 20px;
    text-align: left;
    color: #ffffff;
    line-height: 150%;
    overflow: auto;
}

#pagefooter a.umich {
    display: block;
    width: 140px;
    height: 152px;
    background: #00274c url(/static/media/um-logo-m216.8f4748ef.png) no-repeat;
    background-size: cover;
    text-indent: -99999px;
}

#pagefooter .links h2 {
    margin: auto;
    font-size: 1em;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color: #ffffff;
    line-height: inherit;
}

#pagefooter .links {
    float: left;
    /*	width:40%;*/
    margin: 0 40px 2em 0;
    padding: 0;
    white-space: nowrap;
}

#pagefooter .links ul {
    margin: 0 0 0 10px;
    padding: 0 0 0 10px;
}

#pagefooter .links ul li {
    color: #555555;
    margin: .5em 0 0 0;
}

#pagefooter .links .socialmedia {
    margin: .5em 0 1em 0;
}

#pagefooter .links .socialmedia a:hover {
    text-decoration: none;
}

#pagefooter .social-icon {
    display: inline-block;
    width: 1.25em;
    font-size: 1.5em;
    font-family: fontawesome;
    font-weight: normal;
    font-style: normal;
    text-rendering: optimizelegibility;
    text-align: center;
    vertical-align: middle;
    line-height: 1.25em;
}

#pagefooter a:link, #pagefooter a:visited {
    color: #ffffff;
    text-decoration: none;
}

#pagefooter a:hover {
    color: #ffcb05;
    text-decoration: underline;
}

#pagefooter a:active {
    color: #ffcb05;
    text-decoration: underline;
}

#pagefooter .links .socialmedia {
    margin: .5em 0 1em 0;
}

#pagefooter .links .socialmedia a {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    line-height: 2.7rem;
    margin-right: .5em;
    border: 1px solid #555555;
    border-radius: 100%;
    font-size: 1.15rem;
    font-family: 'Fontawesome';
    text-align: center;
}

#pagefooter .links .socialmedia a:hover {
    background: #555555;
    color: #ffffff;
    text-decoration: none;
}

#pagefooter .links .socialmedia a:active, .region-footer .links .socialmedia a:focus {
    background: #0075bc;
    border: 1px solid #0075bc;
    color: #ffffff;
    text-decoration: none;
}

#pagefooter .links.right {
    float: right;
}

#pagefooter .links.right ul li:first-child {
    margin: 0;
}

#pagefooter .copyright {
    clear: both;
    font-size: .85em;
    color: #9b9b9b;
    /*	text-align:right;*/
}

#pagefooter .copyright a:link, .copyright a:visited {
    color: #9b9b9b;
    text-decoration: none;
}

#pagefooter .copyright a:hover {
    color: #ffcb05;
    text-decoration: underline;
}

#pagefooter .copyright a:focus {
    outline: thin dotted #ffffff;
    background: #0075bc;
    color: #ffffff;
}

/* RESPONSIVE STYLES */

@media (max-width: 1279px) {
    #pageBanner,
    #breadcrumb,
    #pagecontent,
    #pagefooter {
        width: auto;
        min-width: 0;
    }
}

@media (max-width: 959px) {
    #pageBanner {
        width: auto;
        min-width: 0;
    }

    #pageBanner #banner {
        margin: 1em 0 1em 0;
    }
}

@media (max-width: 499px) {
    #pagefooter .links,
    #pagefooter .links.right {
        float: none;
    }
}

/* CHAT AREA STYLES */

#chat-form {
    margin: 2em 0 1em 0;
    padding: 20px;
    border: 1px solid #cecbc9;
    background: #e4e1df;
}

#chat-form form label {
    display: inline-block;
    min-width: 7em;
    margin-bottom: 1rem;
}



#chat-form form input[value="Start Chat"] {
    margin-left: 7em !important;
}

#chat-form .ChatApp #chat-window {
    width: 100%;
    height: 480px;
    padding: 20px;
    border: 1px solid #cecbc9;
    background: #fffefe;
    position: relative;
    overflow-y: hidden;
}
#chat-form .ChatApp #chat-window:hover {
    width: 100%;
    height: 480px;
    padding: 20px;
    border: 1px solid #cecbc9;
    background: #fffefe;
    position: relative;
    overflow-y: auto;
}

#chat-form .ChatApp #chat-window .message-event {
    margin: 0 0 20px 0;
    padding: 20px;
    width: 100%;
    background: inherit;
    overflow: hidden;
    text-align: center;
}

#chat-form .ChatApp #chat-window .message-customer {
    float: right;
    margin: 0 0 20px 0;
    padding: 20px;
    width: 100%;
    max-width: 40em;
    background: #cfe2f3;
    border-radius: 20px 20px 5px 20px;
    /*	box-shadow:0 4px 10px 0 #e4e1df;*/
    overflow: hidden;
}

#chat-form .ChatApp #chat-window .message-customer div {
    text-align: right;
}

#chat-form .ChatApp #chat-window .message-system,
#chat-form .ChatApp #chat-window .message-agent {
    clear: right;
    margin: 0 0 20px 0;
    padding: 20px;
    max-width: 40em;
    background: #f4f3f2;
    border-radius: 20px 20px 20px 5px;
}


#chat-form .ChatApp #chat-window .agentTyping {
    clear: right;
    margin: 0 0 20px 0;
    padding: 20px;
    max-width: 2em;
    background: #f4f3f2;
    border-radius: 20px 20px 20px 5px;
    color: #6f6f6f;
    font-size: 2rem;
}

#chat-form .ChatApp #chat-window .agentTyping:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    width: 0px;
    content: "\2026";
    animation: ellipsis steps(4, end) 900ms infinite;
}

@keyframes ellipsis {
    to {
        width: 1.7rem;
    }
}

#chat-form .ChatApp #chat-window .label {
    position: absolute;
    left: -9999999px;
}
#chat-form .ChatApp #chat-window .attachmentUploading {
    clear: right;
    margin: 0 0 20px 0;
    padding: 20px;
    max-width: 2em;
    background: #f4f3f2;
    border-radius: 20px 20px 5px 20px;
    color: #6f6f6f;
    font-size: 2rem;
    float:right;
    width: 100%;
    
    
    

   
    
   
}


#chat-form .ChatApp #chat-window .attachmentUploading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    width: 0px;
    content: "\2026";
    animation: ellipsis steps(4, end) 900ms infinite;
    
}

@keyframes ellipsis {
    to {
        width: 1.7rem;
    }
}

#chat-form .ChatApp #chat-window .label[id='attachLabel'] {
    
    right:6em;
    font-size: .5em;
    text-align:right;
    left:auto;
   
    
}

#chat-form .ChatApp #chat-window .from {
    margin-bottom: .5rem;
    font-size: .75rem;
    color: #6f6f6f;
    text-transform: uppercase;
}

#chat-form .ChatApp #chat-window .message {
    white-space: pre-line;
    color: #333333;
    
}

#chat-form .ChatApp #chat-window .sent {
    margin-top: .5rem;
    font-size: .75rem;
    color: #6f6f6f;
}

#chat-form .ChatApp #chat-window {
    clear: right;
}

#chat-form .ChatApp #chat-window p {
    margin: 0;
}

#chat-form .ChatApp #chat-window hr {
    display: none;
}

#chat-form .ChatApp .chat-form {
    padding: 20px 0 0 0;
}

#chat-form .ChatApp .chat-form .input-group {
    display: flex;
    flex-direction: row nowrap;
}

#chat-form .ChatApp .chat-form .input-group .input-text {
    flex-grow: 1;
    padding-left: .5%;
    padding-right: .5%;
    position: relative;
}
#chat-form .ChatApp .chat-form .input-group .input-text input[id=message]{
    padding-left: 2.5em;
}


#chat-form .ChatApp .chat-form input[type="text"]{
    width: 100%;
    
}

#chat-form .ChatApp input[type="text"] {
    height: auto;
    background: #ffffff;
    border: 1px solid #cecbc9;
    border-radius: 4px;
    padding: .5em;
    font-size: inherit;
    font-family: inherit;
    width:auto;
    
}

#chat-form .ChatApp input[type="text"]:focus {
    outline: none;
    -webkit-appearance: none;
    border: 1px solid #575fc9;
    background: #ffffff; 
    box-shadow: 0 0 2px #575fc9;
    -webkit-box-shadow: 0 0 2px #575fc9;
}

#chat-form .ChatApp .chat-form input[type="email"]{
    width: 100%;
}

#chat-form .ChatApp input[type="email"] {
    height: auto;
    background: #ffffff;
    border: 1px solid #cecbc9;
    border-radius: 4px;
    padding: .5em;
    font-size: inherit;
    font-family: inherit;
    width:auto;
    
}

#chat-form .ChatApp input[type="email"]:focus {
    outline: none;
    -webkit-appearance: none;
    border: 1px solid #575fc9;
    background: #ffffff;
    box-shadow: 0 0 2px #575fc9;
    -webkit-box-shadow: 0 0 2px #575fc9;
}

#chat-form .ChatApp input[type="button"], #chat-form .ChatApp input[type="submit"], #chat-form .ChatApp button {
    display: inline-block;
    margin: 0;
    padding: .5em 2em .5em 2em;
    border: 0;
    background: #40658f;
    color: #ffffff;
    -webkit-appearance: none;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: inherit;
    font-family: inherit;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    line-height: normal;
    height: auto;
    white-space: nowrap;
    cursor: pointer;
    
    
}
#chat-form .ChatApp #chat-window .list .listButtons{
    display: inherit;
   
    
}

#chat-form .ChatApp #chat-window .list .listButtons button{
    display: block;
    margin: .3em .3em .1em .3em;
    padding: .5em .5em .5em .5em;
    border: 0;
    background: #40658f;
    color: #ffffff;
    -webkit-appearance: none;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: inherit;
    font-family: inherit;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    line-height: normal;
    height: auto;
    white-space: normal;
    cursor: pointer;
    width:inherit;
   
    
   
    
    
}

#chat-form .ChatApp input[type="input"]:hover, #chat-form .ChatApp input[type="button"]:focus, #chat-form .ChatApp input[type="submit"]:hover, #chat-form .ChatApp input[type="submit"]:focus, #chat-form .ChatApp button:focus, #chat-form .ChatApp button:hover {
    background: #567daa;
}


.divider{
    width:10%;
    height:auto;
    display:inline-block;
}

#chat-form .ChatApp input[id="endChat"] {
  
background: #b44942;
padding: .5em .5em .5em .5em;
height: auto;
width: 30%;
margin-right: 5%;
flex-shrink: 1;





}
#chat-form .ChatApp input[id="attach"] {
    background: none;
    color: black;
    display: inline;
    border: none;
    position: absolute;
    visibility: hidden;
    left: 0;
    padding: 4px !important;
    bottom: 4px !important;
    
    font-weight: bold;
    
    
  margin-bottom: 0;
    cursor: pointer;
    min-width: inherit;
    min-height: inherit;
}

#chat-form .ChatApp label[for="attach"] {
    background: none;
    color: black;
    display: inline;
    border: none;
    position: absolute;
    left: 0;
    padding-left: 5px;
    font-weight: bold;
    top:5px;
    bottom: 0;

    
    
  margin-bottom: 0;
    cursor: pointer;
    min-width: inherit;
    min-height: inherit;
}

#chat-form .ChatApp label[for="attach"]:hover {
    color:grey;
}

#chat-form .ChatApp input[type="input"]:hover, #chat-form .ChatApp input[id="endChat"]:focus, #chat-form .ChatApp input[id="endChat"]:hover {
    background: #cc6861;
} 

#chat-form .ChatApp input[id="sendChat"] {
    background: #40658f;
    color: #ffffff;
    padding: .5em .5em .5em .5em;
    width: 65%;

    
    
}

.required-field::after {
    content: '*';
    color: #e00000;
    display: inline-block;
}

#chat-form .ChatApp input[id="sendChat"]:hover, #chat-form .ChatApp input[id="sendChat"]:focus {
    background: #567daa; 
} 

.divider2{
    width:10px;
    height:auto;
    display:inline-block;
}
.divider3{
    width: auto;
    height:auto;
    display:inline-block;
}
img {
    vertical-align: middle;
  }
  .buttons {
    width: 100%;
    height: inherit;
    margin-top: 15px;
    position: relative;
    padding-left: 2%;
    padding-right: 2%;
    -webkit-text-size-adjust: auto;
       -moz-text-size-adjust: auto;
            text-size-adjust: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }
  #require-key {
    color: #e00000;
    display: inline-block;
    
    padding-right:5px;
    -webkit-text-size-adjust: auto;
       -moz-text-size-adjust: auto;
            text-size-adjust: auto;
    font-style: italic;
    font-size: 10pt;
    padding-top: 5px;
    margin-bottom: -100px;
    
    
  }
  

a   {
    color: #0075bc;
    text-decoration: underline;
}



a:hover{
    color: #0074bc88;
    text-decoration: underline;
    cursor: pointer;
}
