/* CHAT AREA STYLES */

#chat-form {
    margin: 2em 0 1em 0;
    padding: 20px;
    border: 1px solid #cecbc9;
    background: #e4e1df;
}

#chat-form form label {
    display: inline-block;
    min-width: 7em;
    margin-bottom: 1rem;
}



#chat-form form input[value="Start Chat"] {
    margin-left: 7em !important;
}

#chat-form .ChatApp #chat-window {
    width: 100%;
    height: 480px;
    padding: 20px;
    border: 1px solid #cecbc9;
    background: #fffefe;
    position: relative;
    overflow-y: hidden;
}
#chat-form .ChatApp #chat-window:hover {
    width: 100%;
    height: 480px;
    padding: 20px;
    border: 1px solid #cecbc9;
    background: #fffefe;
    position: relative;
    overflow-y: auto;
}

#chat-form .ChatApp #chat-window .message-event {
    margin: 0 0 20px 0;
    padding: 20px;
    width: 100%;
    background: inherit;
    overflow: hidden;
    text-align: center;
}

#chat-form .ChatApp #chat-window .message-customer {
    float: right;
    margin: 0 0 20px 0;
    padding: 20px;
    width: 100%;
    max-width: 40em;
    background: #cfe2f3;
    border-radius: 20px 20px 5px 20px;
    /*	box-shadow:0 4px 10px 0 #e4e1df;*/
    overflow: hidden;
}

#chat-form .ChatApp #chat-window .message-customer div {
    text-align: right;
}

#chat-form .ChatApp #chat-window .message-system,
#chat-form .ChatApp #chat-window .message-agent {
    clear: right;
    margin: 0 0 20px 0;
    padding: 20px;
    max-width: 40em;
    background: #f4f3f2;
    border-radius: 20px 20px 20px 5px;
}


#chat-form .ChatApp #chat-window .agentTyping {
    clear: right;
    margin: 0 0 20px 0;
    padding: 20px;
    max-width: 2em;
    background: #f4f3f2;
    border-radius: 20px 20px 20px 5px;
    color: #6f6f6f;
    font-size: 2rem;
}

#chat-form .ChatApp #chat-window .agentTyping:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    width: 0px;
    content: "\2026";
    animation: ellipsis steps(4, end) 900ms infinite;
}

@keyframes ellipsis {
    to {
        width: 1.7rem;
    }
}

#chat-form .ChatApp #chat-window .label {
    position: absolute;
    left: -9999999px;
}
#chat-form .ChatApp #chat-window .attachmentUploading {
    clear: right;
    margin: 0 0 20px 0;
    padding: 20px;
    max-width: 2em;
    background: #f4f3f2;
    border-radius: 20px 20px 5px 20px;
    color: #6f6f6f;
    font-size: 2rem;
    float:right;
    width: 100%;
    
    
    

   
    
   
}


#chat-form .ChatApp #chat-window .attachmentUploading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    width: 0px;
    content: "\2026";
    animation: ellipsis steps(4, end) 900ms infinite;
    
}

@keyframes ellipsis {
    to {
        width: 1.7rem;
    }
}

#chat-form .ChatApp #chat-window .label[id='attachLabel'] {
    
    right:6em;
    font-size: .5em;
    text-align:right;
    left:auto;
   
    
}

#chat-form .ChatApp #chat-window .from {
    margin-bottom: .5rem;
    font-size: .75rem;
    color: #6f6f6f;
    text-transform: uppercase;
}

#chat-form .ChatApp #chat-window .message {
    white-space: pre-line;
    color: #333333;
    
}

#chat-form .ChatApp #chat-window .sent {
    margin-top: .5rem;
    font-size: .75rem;
    color: #6f6f6f;
}

#chat-form .ChatApp #chat-window {
    clear: right;
}

#chat-form .ChatApp #chat-window p {
    margin: 0;
}

#chat-form .ChatApp #chat-window hr {
    display: none;
}

#chat-form .ChatApp .chat-form {
    padding: 20px 0 0 0;
}

#chat-form .ChatApp .chat-form .input-group {
    display: flex;
    flex-direction: row nowrap;
}

#chat-form .ChatApp .chat-form .input-group .input-text {
    flex-grow: 1;
    padding-left: .5%;
    padding-right: .5%;
    position: relative;
}
#chat-form .ChatApp .chat-form .input-group .input-text input[id=message]{
    padding-left: 2.5em;
}


#chat-form .ChatApp .chat-form input[type="text"]{
    width: 100%;
    
}

#chat-form .ChatApp input[type="text"] {
    height: auto;
    background: #ffffff;
    border: 1px solid #cecbc9;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: .5em;
    font-size: inherit;
    font-family: inherit;
    width:auto;
    
}

#chat-form .ChatApp input[type="text"]:focus {
    outline: none;
    -webkit-appearance: none;
    border: 1px solid #575fc9;
    background: #ffffff; 
    box-shadow: 0 0 2px #575fc9;
    -webkit-box-shadow: 0 0 2px #575fc9;
}

#chat-form .ChatApp .chat-form input[type="email"]{
    width: 100%;
}

#chat-form .ChatApp input[type="email"] {
    height: auto;
    background: #ffffff;
    border: 1px solid #cecbc9;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: .5em;
    font-size: inherit;
    font-family: inherit;
    width:auto;
    
}

#chat-form .ChatApp input[type="email"]:focus {
    outline: none;
    -webkit-appearance: none;
    border: 1px solid #575fc9;
    background: #ffffff;
    box-shadow: 0 0 2px #575fc9;
    -webkit-box-shadow: 0 0 2px #575fc9;
}

#chat-form .ChatApp input[type="button"], #chat-form .ChatApp input[type="submit"], #chat-form .ChatApp button {
    display: inline-block;
    margin: 0;
    padding: .5em 2em .5em 2em;
    border: 0;
    background: #40658f;
    color: #ffffff;
    -webkit-appearance: none;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: inherit;
    font-family: inherit;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    line-height: normal;
    height: auto;
    white-space: nowrap;
    cursor: pointer;
    
    
}
#chat-form .ChatApp #chat-window .list .listButtons{
    display: inherit;
   
    
}

#chat-form .ChatApp #chat-window .list .listButtons button{
    display: block;
    margin: .3em .3em .1em .3em;
    padding: .5em .5em .5em .5em;
    border: 0;
    background: #40658f;
    color: #ffffff;
    -webkit-appearance: none;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: inherit;
    font-family: inherit;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    line-height: normal;
    height: auto;
    white-space: normal;
    cursor: pointer;
    width:inherit;
   
    
   
    
    
}

#chat-form .ChatApp input[type="input"]:hover, #chat-form .ChatApp input[type="button"]:focus, #chat-form .ChatApp input[type="submit"]:hover, #chat-form .ChatApp input[type="submit"]:focus, #chat-form .ChatApp button:focus, #chat-form .ChatApp button:hover {
    background: #567daa;
}


.divider{
    width:10%;
    height:auto;
    display:inline-block;
}

#chat-form .ChatApp input[id="endChat"] {
  
background: #b44942;
padding: .5em .5em .5em .5em;
height: auto;
width: 30%;
margin-right: 5%;
flex-shrink: 1;





}
#chat-form .ChatApp input[id="attach"] {
    background: none;
    color: black;
    display: inline;
    border: none;
    position: absolute;
    visibility: hidden;
    left: 0;
    padding: 4px !important;
    bottom: 4px !important;
    
    font-weight: bold;
    
    
  margin-bottom: 0;
    cursor: pointer;
    min-width: inherit;
    min-height: inherit;
}

#chat-form .ChatApp label[for="attach"] {
    background: none;
    color: black;
    display: inline;
    border: none;
    position: absolute;
    left: 0;
    padding-left: 5px;
    font-weight: bold;
    top:5px;
    bottom: 0;

    
    
  margin-bottom: 0;
    cursor: pointer;
    min-width: inherit;
    min-height: inherit;
}

#chat-form .ChatApp label[for="attach"]:hover {
    color:grey;
}

#chat-form .ChatApp input[type="input"]:hover, #chat-form .ChatApp input[id="endChat"]:focus, #chat-form .ChatApp input[id="endChat"]:hover {
    background: #cc6861;
} 

#chat-form .ChatApp input[id="sendChat"] {
    background: #40658f;
    color: #ffffff;
    padding: .5em .5em .5em .5em;
    width: 65%;

    
    
}

.required-field::after {
    content: '*';
    color: #e00000;
    display: inline-block;
}

#chat-form .ChatApp input[id="sendChat"]:hover, #chat-form .ChatApp input[id="sendChat"]:focus {
    background: #567daa; 
} 

.divider2{
    width:10px;
    height:auto;
    display:inline-block;
}
.divider3{
    width: auto;
    height:auto;
    display:inline-block;
}
img {
    vertical-align: middle;
  }
  .buttons {
    width: 100%;
    height: inherit;
    margin-top: 15px;
    position: relative;
    padding-left: 2%;
    padding-right: 2%;
    text-size-adjust: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }
  #require-key {
    color: #e00000;
    display: inline-block;
    
    padding-right:5px;
    text-size-adjust: auto;
    font-style: italic;
    font-size: 10pt;
    padding-top: 5px;
    margin-bottom: -100px;
    
    
  }
  

a   {
    color: #0075bc;
    text-decoration: underline;
}



a:hover{
    color: #0074bc88;
    text-decoration: underline;
    cursor: pointer;
}